<template>
  <v-row align="center" justify="center">
    <v-col
      cols="12"
      md="4"
      v-for="(i, index) in getInvestmentPlan"
      :key="index"
      data-aos="zoom-out-down"
    >
      <v-hover v-slot="{ hover }" open-delay="200">
        <v-card
          :elevation="hover ? 16 : 2"
          class="mx-auto card"
          height="490"
          max-width="350"
        >
          <v-card-text class="my-4 text-h6 text-center goldColor">
            {{ i.investmentName }}
          </v-card-text>
          <v-card-text class="my-4 text-h6 text-center goldColor">
            BTC/ETH/BNB
          </v-card-text>
          <div class="list text-center">
            <div class="whiteColor">{{ i.dailyReturnRate }}% Daily Return</div>
            <v-divider dark class="ma-2"></v-divider>
            <div class="text-h6 text-center goldColor">duration</div>
            <v-divider dark class="ma-2"></v-divider>

            <div class="whiteColor">{{ i.duration }} Month(s)</div>

            <v-divider dark class="ma-2"></v-divider>
            <div class="whiteColor">
              Total {{ i.totalReturnRate }}% +
              <v-btn class="ma-3" x-small color="#d4b506" light>
                capital
              </v-btn>
            </div>
            <v-divider dark class="ma-2"></v-divider>
            <div class="whiteColor">
              {{ DisplayCurrency(i.lowestInvestmentAmount) }} -
              {{ DisplayCurrency(i.hightestInvestmentAmount) }}
            </div>
            <v-divider dark class="ma-2"></v-divider>
          </div>
          <div class="text-center mt-5 mb-10 pb-5">
            <v-btn
              @click="navigate('DepositDetails', i)"
              class="ma-3"
              large
              color="#d4b506"
              light
            >
              Invest Now
            </v-btn>
          </div>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import currencyFormatter from "currency-formatter";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  methods: {
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "USD",
      });
    },
    navigate(payload, payload1) {
      let payload2 = {
        amount: payload1.hightestInvestmentAmount,
        coin: "Bitcoin",
        ...payload1,
      };
      this.$store.commit("setInvestmentPlan", payload2);
      this.$router.push({ name: payload });
    },
    GetInvestmentPlan() {
      let payload = {
        token: this.getToken,
      };
      this.$store.dispatch("GetInvestmentPlan", payload);
    },
  },
  computed: {
    getInvestmentPlan() {
      return this.$store.getters.getInvestmentPlan;
    },
  },
  created() {
    this.GetInvestmentPlan();
    AOS.init({
      once: true,
      offset: 300, // offset (in px) from the original trigger point
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 700, // values from 0 to 3000, with step 50ms
    });
  },
};
</script>

<style scoped>
.card {
  border: 4px solid #d4b506 !important;
  border-radius: 10px !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/abstract-g4d32a471b_640.jpg?alt=media&token=89c31d44-0b2f-4d5a-b9dd-21e6dcc385fd");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.goldColor {
  color: #d4b506 !important;
}
.whiteColor {
  color: #fff !important;
}
.list {
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>

<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>